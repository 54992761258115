







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  apiAdminAdd,
  apiAdminDetail,
  apiAdminEdit,
  apiRoleList,
} from '@/api/setting/permissions'
import { AdminAdd_Req, AdminEdit_Req } from '@/api/setting/permissions.d'
import { PageMode } from '@/utils/type'
import MaterialSelect from '@/components/material-select/index.vue'

@Component({
  components: {
    MaterialSelect,
  },
})
export default class AdminEdit extends Vue {
  /** S Data **/
  mode: string = PageMode['ADD'] // 当前页面【add: 添加管理员 | edit: 编辑管理员】
  identity: number | null = null // 当前编辑用户的身份ID  valid: mode = 'edit'
  roleList: Array<object> = [] // 角色的数据

  // 添加管理员表单数据
  form: any = {
    account: '', // 账号
    name: '', // 名称
    password: '', // 密码
    password_confirm: '', // 确认密码
    role_id: '', // 角色id
    disable: 0, // 禁用：0-否；1-是
    multipoint_login: 1, // N端登录：0-否；1-是
    avatar: '', // 头像路径
  }

  // 校验密码
  validatePassword = [
    { required: true, message: '请输入密码', trigger: 'blur' },
    {
      validator: (rule: object, value: string, callback: any) => {
        !value ? callback(new Error('请输入密码')) : callback()
      },
      trigger: 'blur',
    },
  ]

  // 校验确认密码
  validatePasswordConfirm = [
    { required: true, message: '请再次输入密码', trigger: 'blur' },
    {
      validator: (rule: object, value: string, callback: any) => {
        if (this.form.password) {
          if (!value) callback(new Error('请再次输入密码'))
          if (value !== this.form.password)
            callback(new Error('两次输入密码不一致!'))
        }
        callback()
      },
      trigger: 'blur',
    },
  ]

  // 表单校验
  rules = {
    account: [
      { required: true, message: '请输入账号', trigger: 'blur' },
      { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' },
    ],
    name: [
      { required: true, message: '请输入活动名称', trigger: 'blur' },
      { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' },
    ],
    role_id: [{ required: true, message: '请选择角色', trigger: 'change' }],
    password: [],
    password_confirm: [],
  }

  /** E Data **/

  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      // 请求发送
      switch (this.mode) {
        case PageMode['ADD']:
          return this.handleAdminAdd()
        case PageMode['EDIT']:
          return this.handleAdminEdit()
      }
    })
  }

  // 添加管理员
  handleAdminAdd() {
    const form = this.form as AdminAdd_Req
    apiAdminAdd(form)
      .then(() => {
        this.$message.success('添加成功!')
        setTimeout(() => this.$router.go(-1), 500)
      })
      .catch(() => {
        this.$message.error('保存失败!')
      })
  }

  // 编辑管理员
  handleAdminEdit() {
    const params = this.form
    const id: number = this.identity as number
    apiAdminEdit({ ...params, id } as AdminEdit_Req)
      .then(() => {
        this.$message.success('修改成功!')
        setTimeout(() => this.$router.go(-1), 500)
      })
      .catch(() => {
        this.$message.error('保存失败!')
      })
  }

  // 获取角色列表
  geRoleList() {
    apiRoleList({
      page_type: 1,
    }).then((res) => {
      this.roleList = res.lists
    })
  }

  // 表单初始化数据 [编辑模式] mode => edit
  initFormDataForAdminEdit() {
    apiAdminDetail({
      id: this.identity as number,
    })
      .then((res) => {
        Object.keys(res).map((key) => {
          this.$set(this.form, key, res[key])
        })
        console.log(this.form)
      })
      .catch(() => {
        this.$message.error('数据初始化失败，请刷新重载！')
      })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    const query: any = this.$route.query

    if (query.mode) this.mode = query.mode

    // 编辑模式：初始化数据
    if (this.mode === PageMode['EDIT']) {
      this.identity = query.id * 1
      this.initFormDataForAdminEdit()
    }

    if (this.mode === PageMode['ADD']) {
      this.$set(this.rules, 'password', this.validatePassword)
      this.$set(this.rules, 'password_confirm', this.validatePasswordConfirm)
    }

    // 获取角色列表
    this.geRoleList()
  }

  @Watch('form.password')
  changePasswordInput(value: string) {
    if (this.mode === PageMode['EDIT']) {
      value
        ? this.$set(
            this.rules,
            'password_confirm',
            this.validatePasswordConfirm
          )
        : this.$set(this.rules, 'password_confirm', [])
    }
  }

  /** E Life Cycle **/
}
